cei.shared.head = (function (document) {

    function setLinkAttributes(el, relText, elUrl) {
        el.setAttribute('href', elUrl);
        el.setAttribute('rel', relText);
    }
    
    function setLinkElement(relText, linkUrl) {
        var linkElement = document.querySelector('[rel="' + relText + '"]');
        var refElement = document.querySelector('[rel="canonical"]');

        if (linkElement === null) {
            linkElement = document.createElement('link');
        }

        setLinkAttributes(linkElement, relText, linkUrl);

        if (refElement !== null) {
            refElement.parentNode.insertBefore(linkElement, refElement.nextSibling);
        } else {
            refElement = document.querySelector('head');
            refElement.appendChild(linkElement);
        }
    }

    return {
        setLinkElement: setLinkElement
    };

})(window.document);